import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import TrafficToggle from "./TrafficToggle";
import FavoriteButton from "./FavoriteButton";
import InstallButton from "./InstallButton";
import WatchLocationButton from "./WatchLocationButton";

const ActionButtonsMenu = () => {
  return (
    <Wrapper px={4} mb={4}>
      <Divider>
        <WatchLocationButton />
        <TrafficToggle />
        <FavoriteButton />
      </Divider>
      <Divider>
        <InstallButton />
      </Divider>
    </Wrapper>
  );
};
export default ActionButtonsMenu;

const Wrapper = styled(Box)`
  display: flex;
  font-size: 1.25rem;
  justify-content: space-between;
`;
const Divider = styled(Box)`
  display: flex; ;
`;
