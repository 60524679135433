import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";

const PlaceSelect = ({ handleChange }) => {
  return (
    <Wrapper>
      <select onChange={handleChange}>
        <optgroup label="Featured">
          <option value="">Choose type</option>
          <option value="restaurant">Restaurant</option>
          <option value="keyword:fast foods">Fast Food</option>
          <option value="shopping_mall">Shopping Mall</option>
          <option value="lodging">Hotel</option>
          <option value="hospital">Hospital</option>
        </optgroup>
        <optgroup label="More">
          <option value="beauty_salon">Beauty Salon</option>
          <option value="bank">Bank</option>
          <option value="bar">Bar</option>
          <option value="cafe">Cafe/Milktea</option>
          <option value="church">Church</option>
          <option value="convenience_store">Convenience Store</option>
          <option value="dentist">Dentist</option>
          <option value="doctor">Doctor</option>
          <option value="drugstore">Drugstore</option>
          <option value="gym">Gym</option>
          <option value="pet_store">Pet Store</option>
          <option value="school">School</option>
          <option value="spa">Spa</option>
          <option value="supermarket">Supermarket</option>
        </optgroup>
      </select>
    </Wrapper>
  );
};
export default PlaceSelect;

const Wrapper = styled(Box)`
  select {
    border-radius: 0;
    color: ${({ theme }) => theme.colors.black};
    padding: 0.5rem;
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.black};
    border-radius: ${({ theme }) => theme.radii.sm};
    font-size: 0.8rem;
    margin: 0rem;
  }
`;
