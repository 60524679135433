import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { css } from "@emotion/react";

const SearchPlaceItem = ({
  data,
  setCurrentLocation,
  setShowAddress,
  handleClick,
  map,
  ...rest
}) => {
  const { description, type } = data;

  const handleSetLocation = () => {
    if (type === "kv-your-location") {
      setShowAddress(true);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          map.setCenter(pos);
          setCurrentLocation(pos);
          handleClick("Your Location");
          setShowAddress(false);
        });
      } else {
        handleClick("");
        setShowAddress(false);
      }
    } else {
      handleClick(description);
    }
  };

  return (
    <Wrapper type={type} onClick={handleSetLocation} {...rest}>
      <TextSearch>{description}</TextSearch>
    </Wrapper>
  );
};
export default SearchPlaceItem;

const Wrapper = styled(Box)`
  border-bottom: 1px solid black;
  padding: 0.8rem 0;
  cursor: pointer;

  ${({ type }) =>
    type === "kv-your-location"
      ? css`
          border: 1px solid black;
          border-radius: 5px;
          text-align: center;
        `
      : ``};
`;
const TextSearch = styled(Box)`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ theme }) => theme.text.s}
`;
