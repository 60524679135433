import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { AppContext } from "context/AppContext";
import { FaTrafficLight } from "react-icons/fa";

const TrafficToggle = () => {
  const { map } = useContext(AppContext);
  const [trafficLayer, setTrafficLayer] = useState();
  const [toggleMap, setToggleMap] = useState(false);

  useEffect(() => {
    if (map) {
      const _trafficLayer = new google.maps.TrafficLayer();
      setTrafficLayer(_trafficLayer);
    }
  }, [map]);

  const handleToggle = () => {
    setToggleMap((prev) => !prev);
  };
  useEffect(() => {
    if (trafficLayer) {
      if (toggleMap) {
        trafficLayer.setMap(map);
      } else {
        trafficLayer.setMap(null);
      }
    }
  }, [toggleMap, trafficLayer]);

  return (
    <Wrapper mr={[3]} onClick={handleToggle} toggleMap={toggleMap}>
      <FaTrafficLight />
    </Wrapper>
  );
};
export default TrafficToggle;

const Wrapper = styled(Box)`
  display: flex;
  width: fit-content;
  ${({ theme }) => theme.text.s}
  line-height: 100%;
  label {
    align-items: center;
  }
  font-size: inherit;
  color: ${({ toggleMap, theme }) =>
    toggleMap ? theme.colors.brutal.red : "inherit"};
`;
