import React, { useContext } from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { GrInstallOption } from "react-icons/gr";
import { AppContext } from "context/AppContext";

const InstallButton = () => {
  const { setShowInstructions } = useContext(AppContext);

  const handleClick = () => {
    setShowInstructions(true);
  };

  return (
    <Wrapper onClick={handleClick}>
      <GrInstallOption />
    </Wrapper>
  );
};
export default InstallButton;

const Wrapper = styled(Box)`
  font-size: inherit;
  display: flex;
`;
