import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import DestinationHeader from "../DestinationHeader";

import { useJsApiLoader } from "@react-google-maps/api";
import Map from "../Map";
import MenuBar from "../MenuBar";
import { AppContext } from "context/AppContext";

const MainApp = () => {
  const { setExpandAddr, setContentLoaded } = useContext(AppContext);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API,
    libraries: ["places"],
  });

  useEffect(() => {
    if (isLoaded) {
      setTimeout(() => {
        setContentLoaded(true);
        setTimeout(() => {
          setExpandAddr(true);
        }, 500);
      }, 1200);
    }
  }, [isLoaded]);

  return (
    <Wrapper>
      {isLoaded && (
        <>
          <DestinationHeader />
          <Map />
          <MenuBar />
        </>
      )}
    </Wrapper>
  );
};
export default MainApp;

const Wrapper = styled(Box)`
  width: 100%;
  max-width: 500px;
  height: ${({ theme }) => `calc(100vh - ${theme.navHeight})`};
  height: ${({ theme }) => `calc((var(--vh, 1vh) * 100) - ${theme.navHeight})`};
  position: relative;
  margin: auto;
`;
