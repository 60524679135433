import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { AppContext } from "context/AppContext";
import { BsSuitHeartFill } from "react-icons/bs";
import { MENU } from "@/data/constants";

const FavoriteButton = () => {
  const {
    setShowFavorites,
    showFavorites,
    swiper,
    swiperCurrIndex,
    setExpandAddr,
    setExpandMenubar,
  } = useContext(AppContext);
  const [isFavSec, setIsFavSec] = useState(true);

  const handleClick = () => {
    setExpandAddr(false);
    setExpandMenubar(true);
    setShowFavorites((prev) => !prev);
    swiper.slideTo(MENU["route-menu"]);
  };

  useEffect(() => {
    setIsFavSec(showFavorites && swiperCurrIndex === MENU["route-menu"]);
  }, [swiperCurrIndex, showFavorites]);

  return (
    <Wrapper isFavSec={isFavSec} onClick={handleClick} mr={[3]}>
      <BsSuitHeartFill />
    </Wrapper>
  );
};
export default FavoriteButton;

const Wrapper = styled(Box)`
  font-size: inherit;
  display: flex;
  align-items: center;
  color: ${({ isFavSec, theme }) =>
    isFavSec ? theme.colors.brutal.red : "inherit"};
`;
