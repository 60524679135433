import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box, Button, Text } from "theme-ui";
import { BsStarFill, BsStarHalf } from "react-icons/bs";
import { AppContext } from "context/AppContext";
import { findTransit } from "../DestinationHeader";
import { motion } from "framer-motion";
import getCountryEmoji from "util/getCountryEmoji";
import discordReport from "util/discordReport";
import RouteItemAd from "../GoogleAds/RouteItemAd";

const PlaceItem = ({ data, idx }) => {
  const context = useContext(AppContext);
  const {
    setDestinationVal,
    setOriginVal,
    currentLocation,
    setCurrentLocation,
  } = context;

  const [isFindJeep, setIsFindJeep] = useState(false);

  const RenderRating = () => {
    const { rating } = data;
    let render;
    const isFloat = (n) => Number(n) === n && n % 1 !== 0;
    if (rating) {
      render = (
        <>
          {Array(Math.floor(rating))
            .fill(0)
            .map(() => (
              <BsStarFill />
            ))}
          {isFloat(rating) && <BsStarHalf />} <Box ml={1}>({rating})</Box>
        </>
      );
    } else {
      render = <Box>No Ratings.</Box>;
    }
    return render;
  };

  const handleFind = () => {
    if (currentLocation) {
      const originVal = "Your Location";
      const destinationVal = data?.vicinity;
      findTransit({
        ...context,
        originVal,
        destinationVal,
      });
      setOriginVal(originVal);
      setDestinationVal(destinationVal);

      try {
        const emojiFlag = getCountryEmoji();
        discordReport(
          `♨️ Clicking Nearby Place from ${emojiFlag}`,
          process.env.NEXT_PUBLIC_DISCORD_WH_CLICK_NEARBY
        );
      } catch (error) {
        console.log(error);
      }
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          try {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            setIsFindJeep(true);
            setCurrentLocation(pos);
          } catch (error) {
            console.error(error);
          }
        });
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    }
  };

  useEffect(() => {
    if (isFindJeep && currentLocation) {
      handleFind();
      setIsFindJeep(false);
    }
  }, [isFindJeep, currentLocation]);

  const animation = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 },
    transition: {
      duration: 0.5,
      ease: [0.04, 0.62, 0.23, 0.98],
      delay: idx * 0.25,
    },
  };

  return (
    <>
      <Wrapper p={2} mb={[3]} as={motion.div} {...animation}>
        <ImageWrapper>
          {(data?.img || data?.photos) && (
            <img
              src={
                data?.img ||
                `https://maps.googleapis.com/maps/api/place/photo?maxwidth=800&photo_reference=${data.photos[0].photo_reference}&key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API}`
              }
            />
          )}
          {!data?.photos && !data?.img && "No Photo."}
        </ImageWrapper>
        <InfoWrapper ml={3}>
          <TextInfoWrapper>
            <NameText>{data?.name}</NameText>
            <VicinityText mt={2}>{data?.vicinity}</VicinityText>
            <Rating mt={1}>{<RenderRating />}</Rating>
            {data?.opening_hours && (
              <Status mt={1}>
                {data?.opening_hours?.open_now ? "Open now" : "Closed"}
              </Status>
            )}
          </TextInfoWrapper>
          <StyledButton mt={2} onClick={handleFind}>
            Find Jeep
          </StyledButton>
        </InfoWrapper>
      </Wrapper>
      <RouteItemAd />
    </>
  );
};
export default PlaceItem;

const Wrapper = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.black};
  display: flex;
  border-radius: ${({ theme }) => theme.radii.sm};
  color: ${({ theme }) => theme.colors.black};
`;
const ImageWrapper = styled(Box)`
  width: 8rem;
  height: 8rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${({ theme }) => theme.radii.sm};
  }
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.grey[8]};
  border-radius: ${({ theme }) => theme.radii.sm};
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const InfoWrapper = styled(Box)`
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;
const NameText = styled(Text)`
  font-size: 1rem;
  line-height: 120%;
  display: block;
  font-family: roboto-bold;
`;
const VicinityText = styled(Text)`
  font-size: 0.7rem;
  line-height: 121%;
  display: block;
`;

const Rating = styled(Box)`
  font-size: 0.7rem;
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  padding: 0.7rem;
  height: auto;
  font-size: 0.8rem;
  line-height: 100%;
  background-color: ${({ theme }) => theme.colors.black};
`;
const Status = styled(Box)`
  height: auto;
  font-size: 0.65rem;
  line-height: 100%;
  padding: 0.2rem;
  width: auto;
  border: 1px solid ${({ theme }) => theme.colors.black};
`;

const TextInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
