import React, { useContext } from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import RecentJeepneyRoutesItem from "./RecentJeepneyRoutesItem";
import { AppContext } from "context/AppContext";
import AdSpaceRouteItem from "../AdSpaceRouteItem";

const RecentJeepneyRoutes = () => {
  const context = useContext(AppContext);
  const { jeepneyRoutesHistory } = context;

  return (
    <Wrapper>
      {jeepneyRoutesHistory.length > 0 && (
        <HeadingText mb={[3]}>Recent searches</HeadingText>
      )}
      <ListWrapper>
        {jeepneyRoutesHistory?.map((data) => (
          <RecentJeepneyRoutesItem data={data} context={context} />
        ))}
      </ListWrapper>
    </Wrapper>
  );
};
export default RecentJeepneyRoutes;

const Wrapper = styled(Box)`
  height: 100%;

  display: flex;
  flex-direction: column;
`;

const ListWrapper = styled(Box)`
  flex: 1;
  overflow-y: auto;
`;

const HeadingText = styled(Text)`
  ${({ theme }) => theme.text.s};
  display: block;
`;
