import styled from "@emotion/styled";
import React from "react";
import { Box } from "theme-ui";

export const OriginIcon = () => {
  return (
    <Wrapper>
      <OriginStyle>
        <OriginInner />
      </OriginStyle>
    </Wrapper>
  );
};

export const DestinationIcon = () => {
  return (
    <Wrapper>
      <DesinationStyle>
        <DestinationInner />
      </DesinationStyle>
    </Wrapper>
  );
};

export const LineIcon = () => {
  return (
    <LineWrapper>
      <Line />
    </LineWrapper>
  );
};

const Wrapper = styled(Box)`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BaseCircle = styled(Box)`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BaseInner = styled(Box)`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
`;

const OriginStyle = styled(BaseCircle)`
  background-color: ${({ theme }) => theme.colors.black};
`;
const OriginInner = styled(BaseInner)`
  background-color: ${({ theme }) => theme.colors.white};
`;
const DesinationStyle = styled(BaseCircle)`
  border: 1px solid ${({ theme }) => theme.colors.black};
`;
const DestinationInner = styled(BaseInner)`
  background-color: ${({ theme }) => theme.colors.black};
`;
const LineWrapper = styled(Box)`
  height: 1.5rem;
  width: 2.5em;
  display: flex;
  justify-content: center;
  padding: 0.1rem 0;
`;

const Line = styled(Box)`
  height: 100%;
  width: 1px;
  border-left: 0.2rem dotted ${({ theme }) => theme.colors.black};
`;
