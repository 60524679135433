import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box, Container, Text } from "theme-ui";
import { AppContext } from "context/AppContext";
import RouteDetailsItem from "./RouteDetailsItem";
import MultipleAd from "@/components/GoogleAds/MultipleAd";
import { MENU } from "@/data/constants";
import { BsSuitHeart, BsSuitHeartFill } from "react-icons/bs";
import { AiFillPicture } from "react-icons/ai";
import { HashLoader } from "react-spinners";

const RouteDetails = ({ routeIndex, setShowDetails }) => {
  const {
    directionsResult,
    setRouteDetails,
    setRouteData,
    setShowDownloadImage,
    swiper,
    setFavoriteRoutes,
    favoriteRoutes,
    routeDetailsData,
  } = useContext(AppContext);
  if (!routeDetailsData)
    return (
      <LoaderWrapper>
        <HashLoader color="#222" size={30} />
        <Box mt={3}>Please choose a route.</Box>
      </LoaderWrapper>
    );
  const { route, origin, destination } = routeDetailsData;
  const currentRoute = route;
  const { legs } = currentRoute;
  const [leg] = legs;
  const { steps } = leg;

  const [currentIndex, setCurrentIndex] = useState(null);
  const handleBack = () => {
    swiper.slideTo(MENU["route-menu"]);
  };

  const idxFav = favoriteRoutes
    .map((_) => JSON.stringify(_.route))
    .indexOf(JSON.stringify(currentRoute));

  const isFavorite = idxFav !== -1;

  const handleFavorite = () => {
    if (isFavorite) {
      setFavoriteRoutes((prev) => {
        const currentFav = [...prev];
        currentFav.splice(idxFav, 1);
        return currentFav;
      });
    } else {
      setFavoriteRoutes((prev) => [
        { route: currentRoute, origin, destination },
        ...prev,
      ]);
    }
  };

  const RouteDetailsContent = (
    <Wrapper px={4} pb={4}>
      {steps?.map((data, i) => (
        <>
          <RouteDetailsItem
            idx={i}
            currentIndexState={[currentIndex, setCurrentIndex]}
            data={data}
          />{" "}
          {i < steps.length - 1 && (
            <LineWrapper mx={4}>
              <Line />
            </LineWrapper>
          )}{" "}
        </>
      ))}
    </Wrapper>
  );

  useEffect(() => {
    setRouteDetails(RouteDetailsContent);
    setRouteData(currentRoute);
  }, [RouteDetails, currentRoute]);

  return (
    <ContainerWrap>
      <HeaderWrapper px={4} pb={3}>
        <Text onClick={handleBack} sx={{ fontSize: 2 }}>
          ← Back
        </Text>
        <ActionBtnWrapper>
          <Text
            sx={{ fontSize: 3, display: "block" }}
            onClick={() => setShowDownloadImage(true)}
            mr={[3]}
          >
            <AiFillPicture />
          </Text>
          <Text sx={{ fontSize: 3, display: "block" }} onClick={handleFavorite}>
            {isFavorite ? <BsSuitHeartFill /> : <BsSuitHeart />}
          </Text>
        </ActionBtnWrapper>
      </HeaderWrapper>
      {RouteDetailsContent}
    </ContainerWrap>
  );
};
export default RouteDetails;

const ContainerWrap = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled(Box)`
  overflow-y: auto;
  border-top-left-radius: ${({ theme }) => theme.radii.lg};
  border-top-right-radius: ${({ theme }) => theme.radii.lg};
`;
const LineWrapper = styled(Box)`
  width: 5rem;
  height: 2rem;
  display: flex;
  justify-content: center;
`;

const Line = styled(Box)`
  height: 100%;
  width: 1px;
  border-left: 0.2rem dotted ${({ theme }) => theme.colors.black};
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ActionBtnWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

const LoaderWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  div {
    font-size: 0.8rem;
  }
`;
