import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { Autocomplete } from "@react-google-maps/api";
import AnimateHide from "util/AnimateHide";

const AddressInput = ({
  placeholder,
  val,
  setShowAddress,
  type,
  setAddrType,
  addrType,
  showAddress,
  valChange = () => {},
  ...rest
}) => {
  const inputRef = useRef(null);
  const handleChange = () => {
    valChange(inputRef.current.value);
  };

  const [hideInput, setHideInput] = useState(false);

  const timeoutRef = useRef(null);

  useEffect(() => {
    if (showAddress) {
      if (addrType !== type) setHideInput(true);
    } else {
      clearTimeout(timeoutRef.current);
      setHideInput(false);
    }
  }, [showAddress, addrType]);

  return (
    <AnimateHide hide={hideInput}>
      <StyledInput
        onFocus={() => {
          if (timeoutRef.current) clearTimeout(timeoutRef.current);
          setShowAddress(true);
          setAddrType(type);
        }}
        onBlur={() => {
          timeoutRef.current = setTimeout(() => {
            setShowAddress(false);
          }, [250]);
        }}
        value={val}
        ref={inputRef}
        onChange={handleChange}
        placeholder={placeholder}
        onClick={(e) => e.target.setSelectionRange(0, e.target.value.length)}
        as="input"
        {...rest}
      />
    </AnimateHide>
  );
};
export default AddressInput;

const StyledInput = styled(Box)`
  padding: 0.5rem 1.5rem;
  background-color: ${({ theme }) => theme.colors.grey[0]};
  border-radius: 50px;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  border: none;
  width: 100%;
  height: 2.5rem;
  ${({ theme }) => theme.boxShadow};
`;
