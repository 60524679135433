import React, { useContext, useState } from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import { AppContext } from "context/AppContext";

const MenuSwiper = ({ children }) => {
  const { setSwiper, setSwiperCurrIndex } = useContext(AppContext);

  return (
    <Wrapper>
      <Swiper
        onSwiper={(swiper) => setSwiper(swiper)}
        onSlideChange={(swiper) => setSwiperCurrIndex(swiper.activeIndex)}
      >
        {React.Children.map(children, (child) => (
          <SwiperSlide>{child}</SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  );
};
export default MenuSwiper;

const Wrapper = styled(Box)`
  height: 100%;
  .swiper,
  .swiper-slide,
  .swiper-wrapper {
    height: 100%;
  }
`;
