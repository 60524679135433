import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import WalkIcon from "./WalkIcon";
import JeepIcon from "./JeepIcon";
import { AppContext } from "context/AppContext";
import { css } from "@emotion/react";
import * as gtag from "util/gtag";
import RouteItemAd from "@/components/GoogleAds/RouteItemAd";
import getCountryEmoji from "util/getCountryEmoji";
import discordReport from "util/discordReport";
import { MENU } from "@/data/constants";

const RouteItem = ({ data, index, isFavorite }) => {
  if (!data) return null;
  const {
    setRouteIndex,
    routeIndex,
    map,
    swiper,
    setRouteDetailsData,
    directionsResult,
    favoriteRoutes,
    isLast,
  } = useContext(AppContext);
  const { legs } = data;
  const [leg] = legs;
  const { steps, duration } = leg;
  const stepsTransit = steps.filter(
    ({ travel_mode }) => travel_mode === "TRANSIT"
  );
  const [firstTransit] = stepsTransit;
  const [isCurrent, setIsCurrent] = useState(false);

  useEffect(() => {
    setIsCurrent(routeIndex === index);
    setTimeout(() => {
      map.setZoom(12);
    }, 100);
  }, [routeIndex]);

  let firstTransitName =
    firstTransit?.transit?.line?.short_name ||
    firstTransit?.transit?.line?.name;

  if (firstTransitName?.length > 8) {
    firstTransitName = <JeepIcon />;
  }

  const handleRoute = () => {
    swiper.slideTo(MENU["route-details"]);
    setRouteIndex(index);

    if (isFavorite) {
      setRouteDetailsData(favoriteRoutes[index]);
    } else {
      setRouteDetailsData({
        route: directionsResult?.routes[index],
        origin: directionsResult.request.origin,
        destination: directionsResult.request.destination,
      });
    }

    gtag.event("clicked_route", {
      firstTransitName,
    });
    try {
      const emojiFlag = getCountryEmoji();
      discordReport(
        `📍 Someone is clicking a route from ${emojiFlag}`,
        process.env.NEXT_PUBLIC_DISCORD_WH_CLICK_ROUTE
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Wrapper isCurrent={isCurrent} p={4} mb={3} onClick={handleRoute}>
        <TransitInfoWrapper>
          <PrimaryTransit isCurrent={isCurrent}>
            <LargeNumberBox>{firstTransitName || <WalkIcon />}</LargeNumberBox>
          </PrimaryTransit>
          <MoreInfoWrapper isCurrent={isCurrent} ml={2}>
            <JeepTransitWrapper mt={2}>
              <JeepIconWrapper>
                {" "}
                <Text mr={1}>{stepsTransit?.length}</Text>
                <JeepIcon />
              </JeepIconWrapper>
              <TransitMoreInfoWrapper pr={3}>
                {stepsTransit.length > 1 &&
                  stepsTransit?.map(({ travel_mode, transit }, i) => (
                    <SecondaryTransit isCurrent={isCurrent}>
                      {i > 0 ? <Text mx={1}>→</Text> : null}
                      {transit?.line?.short_name || transit?.line?.name}
                    </SecondaryTransit>
                  ))}
              </TransitMoreInfoWrapper>
            </JeepTransitWrapper>
          </MoreInfoWrapper>
        </TransitInfoWrapper>
        <Duration>
          <LargeNumber sx={{ fontSize: 7 }}>
            {Math.round(duration.value / 60)}
          </LargeNumber>
          <Text sx={{ fontSize: 2 }}>mins</Text>
        </Duration>
      </Wrapper>
      <RouteItemAd index={index} isLast={isLast} />
    </>
  );
};
export default RouteItem;

const Wrapper = styled(Box)`
  width: 100%;
  border-radius: ${({ theme }) => theme.radii.sm};
  background-color: ${({ theme, isCurrent }) =>
    isCurrent ? theme.colors.black : theme.colors.white};
  ${({ theme }) => theme.boxShadow};
  color: ${({ theme, isCurrent }) =>
    isCurrent ? theme.colors.white : theme.colors.black};
  display: flex;
  justify-content: space-between;
  * {
    ${({ theme }) => theme.transitionAll};
  }
`;

export const BoxIcon = styled(Box)`
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: ${({ theme }) => theme.radii.sm};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Duration = styled(BoxIcon)`
  height: 5rem;
  width: 5rem;
  background-color: transparent;
  flex-direction: column;
`;

const PrimaryTransit = styled(BoxIcon)`
  height: 5rem;
  width: 5rem;
  background-color: ${({ theme, isCurrent }) =>
    isCurrent ? theme.colors.white : theme.colors.black};
  color: ${({ theme, isCurrent }) =>
    isCurrent ? theme.colors.black : theme.colors.white};
  padding: 0.6rem;
  flex-shrink: 0;
`;

const LargeNumber = styled(Text)`
  line-height: 70%;
`;

const LargeNumberBox = styled(Text)`
  ${({ children }) =>
    children?.length > 4
      ? css`
          line-height: 100%;
          text-align: center;
          font-size: 0.8rem;
        `
      : css`
          line-height: 70%;
          text-align: center;
          font-size: 1.5rem;
        `};
`;

const SecondaryTransit = styled(Text)`
  color: ${({ theme, isCurrent }) =>
    isCurrent ? theme.colors.grey[3] : theme.colors.grey[7]};
  font-size: 0.8rem;
`;

const MoreInfoWrapper = styled(Box)`
  display: flex;
  color: ${({ theme, isCurrent }) =>
    isCurrent ? theme.colors.white : theme.colors.black};
`;
const TransitInfoWrapper = styled(Box)`
  display: flex;
`;

const JeepTransitWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const JeepIconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  font-size: 1rem;
`;

const TransitMoreInfoWrapper = styled(Box)`
  width: 100%;
  word-wrap: break-word;
  line-height: 105%;
`;
