import React from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import AdSense from "react-adsense-ads";

const MultipleAd = ({ width, height, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <TextInfo mb={2}>Ads to support the site 😊</TextInfo>
      <AdSense.Google
        client="ca-pub-6028402156043755"
        slot="8800165772"
        format=""
        style={{ width, height, float: "left" }}
      />
    </Wrapper>
  );
};
export default MultipleAd;

const Wrapper = styled(Box)``;
const TextInfo = styled(Text)`
  font-size: 0.8rem;
  display: block;
`;
