import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box, Button, Select } from "theme-ui";
import axios from "axios";

import examplePlaces from "data/examplePlace";
import PlaceItem from "./PlaceItem";
import PlaceSelect from "./PlaceSelect";
import PlaceInput from "./PlaceInput";
import _ from "lodash";
import { AppContext } from "context/AppContext";
import { SyncLoader } from "react-spinners";
import discordReport from "util/discordReport";
import getCountryEmoji from "util/getCountryEmoji";

const PlaceSuggestions = () => {
  const context = useContext(AppContext);
  const { setCurrentLocation } = context;

  const [placeResults, setPlaceResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  let [selectPlace, setSelectPlace] = useState("");

  const findPlace = async () => {
    const nearByPlace = examplePlaces;
    setPlaceResults(nearByPlace);
  };

  const handleChange = (e) => {
    setSelectPlace(e.target.value);
  };

  useEffect(() => {
    findPlace();
  }, []);

  const handleFind = async () => {
    setIsLoading(true);
    setPlaceResults([]);

    const errorCallBack = () => {
      setIsLoading(false);
      setIsError(true);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        try {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCurrentLocation(pos);
          let keyword = null;
          let type = selectPlace;
          if (selectPlace.match(new RegExp("keyword:"))) {
            keyword = selectPlace.split("keyword:")[1];
            type = null;
          }
          const findNearBy = await axios.post(`/api/findNearBy`, {
            type,
            location: `${pos.lat},${pos.lng}`,
            keyword,
          });
          const { results } = findNearBy?.data;
          const sortResult = _.sortBy(results, (obj) => -obj.rating);
          setPlaceResults(sortResult);
          setIsLoading(false);
          try {
            const emojiFlag = getCountryEmoji();
            discordReport(
              `🗺️ Finding Nearby (${type || keyword}) from ${emojiFlag}`,
              process.env.NEXT_PUBLIC_DISCORD_WH_FIND_NEARBY
            );
          } catch (error) {
            console.log(error);
          }
        } catch (error) {
          console.error(error);
          setIsLoading(false);
          setIsError(true);
        }
      }, errorCallBack);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  return (
    <Wrapper>
      <SearchWrapper mb={3}>
        <PlaceSelect handleChange={handleChange} />
        <StyledButton onClick={handleFind} ml={2}>
          Find Near By
        </StyledButton>
      </SearchWrapper>

      {isLoading ? (
        <LoaderWrapper>
          <SyncLoader color="#222" size={10} />
        </LoaderWrapper>
      ) : (
        <PlacesWrapper>
          {placeResults?.map((data, i) => (
            <PlaceItem key={data["place_id"]} data={data} idx={i} />
          ))}
          {isError && <ErrorWrapper>There's an error occured.</ErrorWrapper>}
        </PlacesWrapper>
      )}
    </Wrapper>
  );
};
export default PlaceSuggestions;

const Wrapper = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const PlacesWrapper = styled(Box)`
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const SearchWrapper = styled(Box)`
  display: flex;
  align-items: flex-end;
`;

const StyledButton = styled(Button)`
  padding: 0.7rem;
  height: auto;
  font-size: 0.8rem;
  line-height: 100%;
  background-color: ${({ theme }) => theme.colors.black};
`;

const LoaderWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ErrorWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
`;
