import React, { useContext } from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import RecentJeepneyRoutes from "../RecentJeepneyRoutes";
import { AppContext } from "context/AppContext";
import { MENU } from "@/data/constants";

const RecentSearchMenu = () => {
  const { swiper } = useContext(AppContext);
  return (
    <Wrapper px={4}>
      <Text
        sx={{ fontSize: 2, display: "block" }}
        mb={3}
        onClick={() => swiper.slideTo(MENU["suggestion-menu"])}
      >
        ← Back
      </Text>
      <RecentJeepneyRoutes />
    </Wrapper>
  );
};
export default RecentSearchMenu;

const Wrapper = styled(Box)`
  height: 100%;
`;
