import React, { useContext } from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import RouteItem from "./RouteItem.js";
import { AppContext } from "context/AppContext.js";
import AdSpaceRouteItem from "../AdSpaceRouteItem/index.js";
import MultipleAd from "../GoogleAds/MultipleAd.js";
import { AnimatePresence, motion } from "framer-motion";
import { MENU } from "@/data/constants.js";

const RouteMenu = ({ setShowDetails }) => {
  const {
    directionsResult,
    favoriteRoutes,
    showFavorites,
    setShowFavorites,
    swiper,
  } = useContext(AppContext);

  const animation = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 },
    transition: { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] },
  };

  return (
    <Wrapper>
      <AnimatePresence>
        {showFavorites ? (
          <BoxWrapper px={4} pb={3} as={motion.div} {...animation} key="1">
            <Text
              sx={{ fontSize: 2, display: "block" }}
              mb={3}
              onClick={() => setShowFavorites(false)}
            >
              ← Back
            </Text>
            {favoriteRoutes &&
              favoriteRoutes?.map((data, i) => (
                <>
                  <FavInfoWrapper>
                    <FavInfoText sx={{ display: "block" }}>
                      {data?.origin?.query}
                    </FavInfoText>
                    →
                    <FavInfoText sx={{ display: "block" }}>
                      {data?.destination?.query}
                    </FavInfoText>
                  </FavInfoWrapper>
                  <RouteItem
                    isFavorite
                    setShowDetails={setShowDetails}
                    index={i}
                    data={data.route}
                    isLast={favoriteRoutes?.length - 1 == i}
                  />
                </>
              ))}
            {favoriteRoutes && favoriteRoutes.length === 0 ? (
              <NoResultWrapper>
                <Text>No favorites.</Text>
              </NoResultWrapper>
            ) : null}
          </BoxWrapper>
        ) : (
          <BoxWrapper px={4} pb={3} as={motion.div} {...animation} key="2">
            <Text
              sx={{ fontSize: 2, display: "block" }}
              mb={3}
              onClick={() => swiper.slideTo(MENU["recent-menu"])}
            >
              ← Back
            </Text>
            {directionsResult &&
              directionsResult?.routes?.map((data, i) => (
                <RouteItem
                  setShowDetails={setShowDetails}
                  index={i}
                  data={data}
                  isLast={_.lastIndexOf(directionsResult?.routes) === i}
                />
              ))}
            {!directionsResult || directionsResult.length === 0 ? (
              <NoResultWrapper>
                <Text>No results...</Text>
                <LearnMoreText mt={2}>
                  No results? We’re sorry! Our team is currently working on it
                  and will expand soon to other major cities. Thank you for
                  understanding!
                </LearnMoreText>
                <LearnMoreText mt={2}>
                  Tip: Choose the nearest landmark possible if your location
                  cannot be found as this may not be available on Google Maps.
                </LearnMoreText>
              </NoResultWrapper>
            ) : null}
          </BoxWrapper>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};
export default RouteMenu;

const Wrapper = styled(Box)`
  height: 100%;
  width: 100%;
  border-top-left-radius: ${({ theme }) => theme.radii.lg};
  border-top-right-radius: ${({ theme }) => theme.radii.lg};
  position: relative;
`;

const NoResultWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
`;

const LearnMoreText = styled(Box)`
  font-size: 0.6rem;
  width: 80%;
  max-width: 400px;
  text-align: center;
`;

const BoxWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
`;

const FavInfoWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.text.s};
  justify-content: space-between;
  padding: 0.2rem 0.6rem;
  border: 1px solid ${({ theme }) => theme.colors.black};
  margin-bottom: 0.8rem;
  border-radius: ${({ theme }) => theme.radii.sm};
`;

const FavInfoText = styled(Text)`
  width: 45%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 100%;
`;
