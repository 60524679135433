import React from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import {
  DestinationIcon,
  LineIcon,
  OriginIcon,
} from "../Icons/DestinationIcons";
import { findTransit } from "../DestinationHeader";

const RecentJeepneyRoutesItem = ({ data, context }) => {
  const { setOriginVal, setDestinationVal } = context;

  const handleClick = () => {
    findTransit({
      ...context,
      originVal: data.originVal,
      destinationVal: data.destinationVal,
    });
    setOriginVal(data.originVal);
    setDestinationVal(data.destinationVal);
  };

  return (
    <Wrapper p={2} mb={[3]} onClick={handleClick}>
      <DestBox>
        <OriginIcon />
        <DestText>{data.originVal}</DestText>
      </DestBox>
      <LineIcon />
      <DestBox>
        <DestinationIcon />
        <DestText>{data.destinationVal}</DestText>
      </DestBox>
    </Wrapper>
  );
};
export default RecentJeepneyRoutesItem;

const Wrapper = styled(Box)`
  width: 100%;
  border-radius: ${({ theme }) => theme.radii.sm};
  border: 1px solid ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
`;

const DestText = styled(Text)`
  ${({ theme }) => theme.text.s};
  width: 80%;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  line-height: 100%;
`;
const DestBox = styled(Box)`
  display: flex;
  align-items: center;
`;
