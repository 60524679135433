import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import PlaceSuggestions from "../PlaceSuggestions";

const SuggestionMenu = () => {
  return (
    <Wrapper px={4}>
      <PlaceSuggestions />
    </Wrapper>
  );
};
export default SuggestionMenu;

const Wrapper = styled(Box)`
  height: 100%;
`;
