import React from 'react';
import styled from '@emotion/styled';
import { Box } from 'theme-ui';

const PlaceInput = () => {
  return (
   <Wrapper mx={3}>
    <input type="text"/>
   </Wrapper>
  );
};
export default PlaceInput

const Wrapper = styled(Box)`
flex: 1;
input {
  padding: 0.5rem 0;
  border: 0;
  background-color: transparent;
  border-radius: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  width: 100%;
  font-size: 0.8rem;
  
}

`