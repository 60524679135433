import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import SearchPlaceItem from "./SearchPlaceItem";
import { AnimatePresence, motion } from "framer-motion";
import googleSearchApi from "util/googleSearchApi";
import suggestionsOrigin from "data/suggestionsOrigin.json";
import AdSpaceRouteItem from "../AdSpaceRouteItem";
import SmallHorizontalAd from "../GoogleAds/SmallHorizontalAd";

const SearchPlacesMenu = ({
  addrType,
  originVal,
  destinationVal,
  setOriginVal,
  setDestinationVal,
  showAddress,
  setExpandMenubar,
  setShowAddress,
  setCurrentLocation,
  map,
}) => {
  const [predictionResults, setPredictionResults] = useState([]);
  const handleClick = (val) => {
    switch (addrType) {
      case "origin":
        setOriginVal(val);
        break;
      case "destination":
        setDestinationVal(val);
        break;
    }
    setShowAddress(false);
  };
  const [suggestion, setSuggestions] = useState(suggestionsOrigin);

  // showAddress = true;

  const inputCooldownRef = useRef(null);

  useEffect(() => {
    setPredictionResults([]);
    if (showAddress) {
      clearTimeout(inputCooldownRef?.current);
      if (originVal !== "" || destinationVal !== "") {
        inputCooldownRef.current = setTimeout(() => {
          searchPlace();
        }, 500);
      } else {
        setPredictionResults(suggestion);
      }
    }
  }, [originVal, destinationVal]);

  useEffect(() => {
    setPredictionResults(suggestion);
    if (showAddress) {
      setExpandMenubar(false);
    }
  }, [showAddress]);

  const searchPlace = async () => {
    let addrVal = "";
    switch (addrType) {
      case "origin":
        addrVal = originVal;
        break;
      case "destination":
        addrVal = destinationVal;
        break;
    }

    if (addrVal !== "") {
      const result = await googleSearchApi(addrVal);
      const { predictions } = result;
      setPredictionResults(predictions);
    }
  };

  return (
    <Wrapper as={motion.div} animate={{ height: showAddress ? "50vh" : 0 }}>
      <Box mt={3}>
        {addrType === "origin" && (
          <SearchPlaceItem
            map={map}
            setShowAddress={setShowAddress}
            setCurrentLocation={setCurrentLocation}
            data={{
              description: "Your Current Location",
              type: "kv-your-location",
              kv_suggestions: true,
            }}
            handleClick={handleClick}
          />
        )}
      </Box>
      {predictionResults[0]?.kv_suggestions && (
        <SuggestionsText mt={3}>Suggestions:</SuggestionsText>
      )}
      <SearchesWrapper>
        {predictionResults?.map((data, i) => (
          <SearchPlaceItem data={data} handleClick={handleClick} />
        ))}
      </SearchesWrapper>
    </Wrapper>
  );
};
export default SearchPlacesMenu;

const Wrapper = styled(Box)`
  width: 100%;
  overflow: hidden;
`;

const SearchesWrapper = styled(Box)`
  width: 100%;
  height: 50%;
  overflow-y: auto;
`;

const SuggestionsText = styled(Text)`
  display: block;
  ${({ theme }) => theme.text.s}
  font-family: ${({ theme }) => theme.fonts.bold};
  line-height: 100%;
  text-transform: uppercase;
`;
