import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";

import { DirectionsRenderer, GoogleMap, Marker } from "@react-google-maps/api";
import { AppContext } from "context/AppContext";
import mapStyle from "theme/mapStyle";

const Map = () => {
  const {
    map,
    setMap,
    directionsResult,
    routeIndex,
    center,
    setCenter,
    zoom,
    currentPos,
  } = useContext(AppContext);

  const options = {
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: false,
  };
  const mapContainerStyle = { width: "100%", height: "100%" };
  useEffect(() => {
    if (map) {
      if (map) {
        const styledMapType = new google.maps.StyledMapType(mapStyle);
        map.mapTypes.set("styled_map", styledMapType);
        map.setMapTypeId("styled_map");
      }
      // const trafficLayer = new google.maps.TrafficLayer();
      // trafficLayer.setMap(map);
      setTimeout(() => {
        setCenter(null);
      }, 1000);
    }
  }, [map]);

  return (
    <Wrapper>
      <GoogleMap
        center={center}
        zoom={zoom}
        mapContainerStyle={mapContainerStyle}
        options={{ options }}
        onLoad={(map) => setMap(map)}
      >
        {currentPos && (
          <Marker
            position={{ lat: currentPos.latitude, lng: currentPos.longitude }}
            icon="/svg/svgLocation.svg"
          />
        )}
        {directionsResult && (
          <DirectionsRenderer
            directions={directionsResult}
            routeIndex={routeIndex}
          />
        )}
      </GoogleMap>
    </Wrapper>
  );
};
export default Map;

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
`;
