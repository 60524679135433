import axios from "axios";

const googleSearchApi = async (val) => {
  try {
    const service = new google.maps.places.AutocompleteService();
    const defaultBounds = {
      north: 11.222234417143698,
      south: 9.419986376862864,
      east: 124.2331078154863,
      west: 123.20313955376756,
    };
    const request = {
      input: val,
      componentRestrictions: { country: "ph" },
      // strictBounds: true,
      // bounds: defaultBounds,
    };
    return service.getPlacePredictions(request);
  } catch (error) {
    console.error("🔎 Google place search error! \n\n", error);
    return null;
  }
};
export default googleSearchApi;
