import React from 'react';
import styled from '@emotion/styled';
import { Box } from 'theme-ui';

const WalkIcon = () => {
  return (
   <Wrapper>
      <svg width="260" height="436" viewBox="0 0 260 436" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M183.43 37.121C183.43 57.624 166.809 74.244 146.307 74.244C125.804 74.244 109.184 57.623 109.184 37.121C109.184 16.618 125.805 -0.00195312 146.307 -0.00195312C166.81 -0.00195312 183.43 16.619 183.43 37.121Z" fill="black"/>
<path d="M89.9999 150.506C89.9999 150.506 59.6609 308.036 53.1289 318.696C47.5424 327.812 3.94891 399.338 3.94891 399.338C-5.3576 414.598 3.46149 425.508 13.3213 431.2C21.7151 436.046 39.7583 434.846 45.7243 424.67C45.7243 424.67 94.0903 349.403 99.3673 333.17C103.021 321.932 111.51 275.313 111.51 275.313C111.51 275.313 158.506 323.783 163.653 332.456C170.141 343.389 182.224 419.599 182.224 419.599C185.114 433.159 198.046 437.581 211.51 435.313C222.143 433.523 233.263 426.328 230.796 413.17C230.796 413.17 220.05 324.81 211.51 310.31C201.319 293.008 146.51 233.881 146.51 233.881L162.224 163.881C162.224 163.881 172.31 184.327 179.367 193.167C187.393 203.22 237.938 232.453 237.938 232.453C242.575 235.563 253.071 233.85 257.224 226.025C260.558 219.744 260.401 209.571 252.939 204.596C252.939 204.596 211.284 178.445 201.51 170.31C190.64 161.28 168.65 101.04 168.65 101.04C161.829 91.6534 152.499 81.2978 136.507 81.0398C120.388 80.7795 112.32 89.6594 105.078 94.6108C105.078 94.6108 43.3306 139.716 37.2206 148.897C31.0737 158.133 20.0776 227.468 20.0776 227.468C18.354 235.368 21.4397 241.721 30.7916 243.897C42.2346 246.559 50.4366 239.433 51.5056 233.897C51.5056 233.897 58.5564 179.132 63.6486 171.04C69.5774 161.618 89.9946 150.519 89.9946 150.519L89.9999 150.506Z" fill="black"/>
    </svg>
   </Wrapper>
  );
};
export default WalkIcon

const Wrapper = styled(Box)`
  height: 2rem;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
    path {
      fill: currentColor;
    }
  }
`