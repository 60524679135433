import whereto from "data/whereto.json";
import _ from "lodash";

const whereToGenerator = () => {
  const random = _.shuffle(whereto).splice(0, _.random(2, 3));
  let arr = [];
  random.forEach((txt) => {
    arr.push(txt);
    arr.push(1000);
  });
  arr.push("Where to?");
  return arr;
};
export default whereToGenerator;
