import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box, Button, Text } from "theme-ui";
import AddressInput from "./AddressInput";
import exampleRoute from "data/route.json";
import {
  DestinationIcon,
  LineIcon,
  OriginIcon,
} from "../Icons/DestinationIcons";
import { AppContext } from "context/AppContext";
import { AnimatePresence, motion } from "framer-motion";
import SearchPlacesMenu from "../SearchPlacesMenu";
import AnimateHide from "util/AnimateHide";
import { TypeAnimation } from "react-type-animation";
import whereToGenerator from "util/whereToGenerator";
import * as gtag from "util/gtag";
import getCountryEmoji from "util/getCountryEmoji";
import discordReport from "util/discordReport";
import { useSwipeable } from "react-swipeable";
import { MENU } from "@/data/constants";

export const findTransit = async (context) => {
  const {
    setDirectionsResult,
    currentLocation,
    setJeepneyRoutesHistory,
    originVal,
    destinationVal,
    setExpandAddr,
    setExpandMenubar,
    swiper,
    setRouteIndex,
    setShowFavorites,
  } = context;

  if (originVal !== "" && destinationVal !== "") {
    try {
      const directionService = new google.maps.DirectionsService();
      let originValue = originVal;
      if (originVal === "Your Location") {
        originValue = `${currentLocation.lat},${currentLocation.lng}`;
      }
      const results = await directionService.route({
        origin: originValue,
        destination: destinationVal,
        travelMode: google.maps.TravelMode.TRANSIT,
        provideRouteAlternatives: true,
      });
      setDirectionsResult(results);
      gtag.event("clicked_find_jeep");
      setExpandAddr(false);
      setExpandMenubar(true);
      setRouteIndex(0);
      setShowFavorites(false);
      setJeepneyRoutesHistory((prev) =>
        [
          ...new Set(
            [{ originVal, destinationVal }, ...prev].map(JSON.stringify)
          ),
        ].map(JSON.parse)
      );

      swiper.slideTo(MENU["route-menu"]);

      // discord report
      try {
        const emojiFlag = getCountryEmoji();
        discordReport(
          `🔍 Someone is finding a Jeep from ${emojiFlag}`,
          process.env.NEXT_PUBLIC_DISCORD_WH_FIND_JEEP
        );
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  }
};

const DestinationHeader = () => {
  const context = useContext(AppContext);
  const {
    setDirectionsResult,
    currentLocation,
    setJeepneyRoutesHistory,
    originVal,
    destinationVal,
    setOriginVal,
    setDestinationVal,
    expandAddr,
    setExpandAddr,
    setExpandMenubar,
    setCurrentLocation,
    map,
    swiper,
  } = context;

  const [showAddress, setShowAddress] = useState(false);
  const [addrType, setAddrType] = useState("");
  const [wheretotext, setWheretotext] = useState(whereToGenerator());
  const [showWhereTo, setShowWhereTo] = useState(false);

  useEffect(() => {
    if (showAddress) {
      setShowWhereTo(false);
    }
  }, [showAddress]);

  useEffect(() => {
    setTimeout(() => {
      setShowWhereTo(true);
    }, 2500);
  }, []);

  const handlers = useSwipeable({
    onSwipedUp: (eventData) => setExpandAddr(false),
    onSwipedDown: (eventData) => setExpandAddr(true),
  });

  const handleFindTransit = () => {
    findTransit(context);
  };

  return (
    <Wrapper
      as={motion.div}
      animate={{ height: "auto" }}
      initial={{ height: "auto" }}
      p={4}
      pb={0}
    >
      <Expanded
        as={motion.div}
        animate={{ height: expandAddr ? "auto" : "0" }}
        initial={{ height: 0 }}
      >
        <WhereToText mb={3}>
          <AnimateHide hide={!showAddress || addrType === "destination"}>
            <Text>Where from?</Text>
          </AnimateHide>
          <AnimateHide hide={showAddress || !showWhereTo}>
            <TypeAnimation sequence={wheretotext} speed={2} cursor={false} />
          </AnimateHide>
          <AnimateHide hide={showAddress || showWhereTo}>
            <Text>Where to?</Text>
          </AnimateHide>
          <AnimateHide hide={!showAddress || addrType === "origin"}>
            <Text>Where are you going to?</Text>
          </AnimateHide>
        </WhereToText>
        <InputWrapper>
          <Icons mr={3}>
            <AnimateHide hide={showAddress && addrType !== "origin"}>
              <OriginIcon />
            </AnimateHide>
            <AnimateHide hide={showAddress}>
              <LineIcon />
            </AnimateHide>
            <AnimateHide hide={showAddress && addrType !== "destination"}>
              <DestinationIcon />
            </AnimateHide>
          </Icons>
          <Inputs>
            <AddressInput
              addrType={addrType}
              setShowAddress={setShowAddress}
              showAddress={showAddress}
              setAddrType={setAddrType}
              val={originVal}
              valChange={setOriginVal}
              placeholder="From"
              type="origin"
              mb={showAddress ? 0 : 4}
            />
            <AddressInput
              addrType={addrType}
              setShowAddress={setShowAddress}
              showAddress={showAddress}
              setAddrType={setAddrType}
              val={destinationVal}
              valChange={setDestinationVal}
              placeholder="To"
              type="destination"
            />
          </Inputs>
        </InputWrapper>
        <AnimateHide hide={showAddress}>
          <ButtonWrapper mt={3}>
            <StyledButton onClick={handleFindTransit}>Find Jeep</StyledButton>
          </ButtonWrapper>
        </AnimateHide>
      </Expanded>
      {!expandAddr && originVal !== "" && destinationVal !== "" ? (
        <Minimized>
          <AddrBox>
            <AddrWrapper>
              <Text sx={{ fontSize: 1 }}>{originVal}</Text>
            </AddrWrapper>
            <Text mx={2} sx={{ fontSize: 1 }}>
              →
            </Text>
            <AddrWrapper>
              <Text sx={{ fontSize: 1 }}>{destinationVal}</Text>
            </AddrWrapper>{" "}
          </AddrBox>
        </Minimized>
      ) : null}
      <SearchPlacesMenu
        setExpandMenubar={setExpandMenubar}
        originVal={originVal}
        destinationVal={destinationVal}
        addrType={addrType}
        setOriginVal={setOriginVal}
        setDestinationVal={setDestinationVal}
        showAddress={showAddress}
        setShowAddress={setShowAddress}
        setCurrentLocation={setCurrentLocation}
        map={map}
      />
      <LineWrapper {...handlers} onClick={() => setExpandAddr((_) => !_)}>
        <Line />
      </LineWrapper>
    </Wrapper>
  );
};
export default DestinationHeader;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.glassEffect};
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
  border-bottom-left-radius: ${({ theme }) => theme.radii.lg};
  border-bottom-right-radius: ${({ theme }) => theme.radii.lg};
`;
const InputWrapper = styled(Box)`
  display: flex;
`;
const Icons = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const Inputs = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: center;
`;
const Expanded = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const Minimized = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
const AddrBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AddrWrapper = styled(Box)`
  width: 35%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const LineWrapper = styled(Box)`
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  height: 3rem;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Line = styled(Box)`
  height: 5px;
  width: 25%;
  background-color: ${({ theme }) => theme.colors.grey[5]};
  border-radius: 50px;
`;

const StyledButton = styled(Button)`
  font-size: 0.8rem;
`;

const WhereToText = styled(Text)`
  &,
  * {
    font-size: 1.1rem;
    font-family: ${({ theme }) => theme.fonts.bold};
    line-height: 100%;
    height: 1.1rem;
  }
`;
