import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { AnimatePresence, motion } from "framer-motion";

const AnimateHide = ({ hide, children }) => {
  const transition = {
    height: { duration: 0.25 },
    opacity: { duration: 0.1 },
    default: { ease: "ease-in-out" },
  };
  return (
    <AnimatePresence ex>
      {!hide && (
        <Wrapper
          as={motion.div}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={transition}
        >
          {children}
        </Wrapper>
      )}
    </AnimatePresence>
  );
};
export default AnimateHide;

const Wrapper = styled(Box)`
  overflow: none;
`;
