import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box, Button, Switch } from "theme-ui";
import RouteMenu from "../Route";
import { AppContext } from "context/AppContext";
import RouteDetails from "../Route/RouteDetails";
import { motion } from "framer-motion";
import _ from "lodash";
import ActionButtonsMenu from "../ActionButtons";
import { useSwipeable } from "react-swipeable";
import MenuSwiper from "../MenuSwiper";
import SuggestionMenu from "../MainMenu/SuggestionMenu";
import RecentSearchMenu from "../MainMenu";

const MenuBar = () => {
  const { expandMenubar, setExpandMenubar, routeIndex } =
    useContext(AppContext);
  const [showDetails, setShowDetails] = useState(false);

  const handleLine = (state = null) => {
    if (state === "up") {
      setExpandMenubar(true);
    } else if (state === "down") {
      setExpandMenubar(false);
    } else {
      setExpandMenubar((_) => !_);
    }
  };
  const handlers = useSwipeable({
    onSwipedUp: (eventData) => handleLine("up"),
    onSwipedDown: (eventData) => handleLine("down"),
  });

  useEffect(() => {
    setTimeout(() => {
      setExpandMenubar(true);
    }, [2000]);
  }, []);

  return (
    <Wrapper>
      <LineWrapper {...handlers} onClick={handleLine} pt={3}>
        <Line />
      </LineWrapper>
      <ActionButtonsMenu />
      <MenuPanelWrapper
        as={motion.div}
        animate={{ height: expandMenubar ? "35vh" : "0" }}
        initial={{ height: 0 }}
      >
        <MenuSwiper>
          <SuggestionMenu />
          <RecentSearchMenu />
          <RouteMenu setShowDetails={setShowDetails} />
          <RouteDetails
            setShowDetails={setShowDetails}
            routeIndex={routeIndex}
          />
        </MenuSwiper>
      </MenuPanelWrapper>
    </Wrapper>
  );
};
export default MenuBar;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.glassEffect};
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 999;
  border-top-left-radius: ${({ theme }) => theme.radii.lg};
  border-top-right-radius: ${({ theme }) => theme.radii.lg};
  overflow: hidden;
  transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
`;

const LineWrapper = styled(Box)`
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  height: 35px;
`;
const Line = styled(Box)`
  height: 5px;
  width: 20%;
  background-color: ${({ theme }) => theme.colors.grey[5]};
  border-radius: 50px;
`;
const MenuPanelWrapper = styled(Box)``;
