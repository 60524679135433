import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";

import MainApp from "@/components/MainApp";
import Seo from "@/components/SEO";

const Index = () => {
  return (
    <>
      <MainApp />
      <Seo
        isHome
        title="MyJeep — Find the Best Jeepney Routes in the Philippines"
        description="Are you confused about what Jeepney to ride? MyJeep is the solution for you. Our website makes it easy to find the best jeepney routes in the Philippines. Get started now!"
        image="/image/og_image.png"
      />
    </>
  );
};
export default Index;

const Wrapper = styled(Box)``;
