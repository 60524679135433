import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { AppContext } from "context/AppContext";
import { MdOutlineGpsFixed } from "react-icons/md";

const WatchLocationButton = () => {
  const id = useRef(null);
  const { setCurrentPos, setCenter, setZoom, setExpandAddr, setExpandMenubar } =
    useContext(AppContext);

  const [isWatch, setIsWatch] = useState(null);

  const handleTarget = () => {
    function success(pos) {
      const crd = pos.coords;
      setCurrentPos(crd);
      setCenter({ lat: crd.latitude, lng: crd.longitude });
    }

    function error(err) {
      console.error(`ERROR(${err.code}): ${err.message}`);
    }

    const options = {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 0,
    };

    id.current = navigator.geolocation.watchPosition(success, error, options);
  };

  useEffect(() => {
    if (isWatch !== null) {
      if (isWatch) {
        setZoom(15);
        handleTarget();
        setExpandAddr(false);
        setExpandMenubar(false);
      } else {
        setExpandAddr(true);
        setExpandMenubar(true);
        navigator.geolocation.clearWatch(id.current);
        setCurrentPos(null);
      }
    }
  }, [isWatch]);

  return (
    <Wrapper mr={3} onClick={() => setIsWatch((_) => !_)} isWatch={isWatch}>
      <MdOutlineGpsFixed />
    </Wrapper>
  );
};
export default WatchLocationButton;

const Wrapper = styled(Box)`
  font-size: inherit;
  display: flex;
  color: ${({ isWatch, theme }) =>
    isWatch ? theme.colors.brutal.red : "inherit"};
`;
