import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import { BoxIcon } from "../RouteItem.js";
import WalkIcon from "../RouteItem.js/WalkIcon.js";
import { AppContext } from "context/AppContext.js";
import { css } from "@emotion/react";
import JeepIcon from "../RouteItem.js/JeepIcon.js";

const RouteDetailsItem = ({ data, idx, currentIndexState }) => {
  const { map } = useContext(AppContext);
  const [currentIndex, setCurrentIndex] = currentIndexState;
  const [isCurrent, setIsCurrent] = useState(false);

  let {
    instructions,
    distance,
    duration,
    travel_mode,
    transit,
    start_location,
  } = data;
  instructions = instructions.replace("Bus towards", "Jeep towards");

  const handleViewInMap = () => {
    setCurrentIndex(idx);
  };

  useEffect(() => {
    if (isCurrent) {
      map.setCenter(start_location);
      map.setZoom(17);
      map.panBy(0, 100);
    }
  }, [isCurrent]);

  useEffect(() => {
    setIsCurrent(currentIndex === idx);
  }, [currentIndex]);

  return (
    <Wrapper isCurrent={isCurrent} p={4} onClick={handleViewInMap}>
      <StepInfoWrapper>
        <MainStep isCurrent={isCurrent}>
          {(() => {
            switch (travel_mode) {
              case "WALKING":
                return <WalkIcon />;
              case "TRANSIT":
                let transitLine =
                  transit?.line?.short_name || transit?.line?.name;
                if (transitLine?.length > 8) {
                  transitLine = <JeepIcon />;
                }
                return (
                  <TransitLine sx={{ fontSize: 5 }}>{transitLine}</TransitLine>
                );
            }
          })()}
          <Box mt={1}>
            <Text sx={{ display: "block" }}>{duration?.text}</Text>
            <Text sx={{ display: "block" }}>{distance?.text}</Text>
          </Box>
        </MainStep>
        <StepInfo ml={3}>
          {(() => {
            switch (travel_mode) {
              case "TRANSIT":
                return <TranistNameText>{transit?.line?.name}</TranistNameText>;
            }
          })()}
          <InstructionsText mt={2}>{instructions}</InstructionsText>
        </StepInfo>
      </StepInfoWrapper>
    </Wrapper>
  );
};
export default RouteDetailsItem;

const Wrapper = styled(Box)`
  width: 100%;
  border-radius: ${({ theme }) => theme.radii.sm};
  background-color: ${({ theme, isCurrent }) =>
    isCurrent ? theme.colors.black : theme.colors.white};
  ${({ theme }) => theme.boxShadow};
  color: ${({ theme, isCurrent }) =>
    isCurrent ? theme.colors.white : theme.colors.black};
  display: flex;
  justify-content: space-between;

  * {
    ${({ theme }) => theme.transitionAll};
  }
`;
const MainStep = styled(BoxIcon)`
  height: 5rem;
  width: 5rem;
  background-color: ${({ theme, isCurrent }) =>
    isCurrent ? theme.colors.white : theme.colors.black};
  color: ${({ theme, isCurrent }) =>
    isCurrent ? theme.colors.black : theme.colors.white};
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 0.6rem;
  line-height: 110%;
  padding: 0.6rem;
`;
const StepInfoWrapper = styled(Box)`
  display: flex;
`;
const StepInfo = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const TransitLine = styled(Text)`
  color: currentColor;
  line-height: 100%;

  ${({ children }) =>
    children?.length > 4
      ? css`
          line-height: 100%;
          text-align: center;
          font-size: 0.8rem;
        `
      : css`
          line-height: 70%;
          text-align: center;
          font-size: 1.5rem;
        `}
`;

const TranistNameText = styled(Text)`
  font-size: 1rem;
  line-height: 110%;
  font-family: ${({ theme }) => theme.fonts.bold};
`;

const InstructionsText = styled(Text)`
  font-size: 0.8rem;
  line-height: 120%;
`;
